.container-lg {
  max-width: 1140px;
}

.navbar {
  z-index: 1001;
  padding-top: 0;
  padding-bottom: 0;
  background-color: var(--bs-white);
  .container {
    max-width: 100%;
  }
}

.navbar-brand-logo {
  // padding: 5px 10px;
  // max-width: 3.5rem;
  // min-width: 3.5rem;
  max-height: 40px;
}

.links-style {
  font-family: "Roboto", sans-serif !important;
  letter-spacing: 1px;
  &.active:after {
    background-color: #377dff;
    @media (min-width: 992px) {
      width: 100%;
    }
  }

  color: #377dff;
  margin-right: 5px;
  text-decoration: none;

  &:after {
    background-color: initial;
    content: "";
    display: block;
    height: 2px;
    margin: auto;
    transition: width 0.5s ease, background-color 0.5s ease;
    width: 0;
  }

  @media (min-width: 992px) {
    &:hover::after {
      background-color: #377dff;
      width: 100%;
    }
  }
}

.navbar-toggler {
  svg {
    fill: var(--bs-white);
    width: 40px;
    height: 40px;
  }
}

.navbar-toggler:focus {
  box-shadow: none;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  transition: top 0.3s ease;
}

[aria-expanded="true"] {
  .navbar-toggler-default {
    display: none;
  }
}

[aria-expanded="false"] {
  .navbar-toggler-toggled {
    display: none;
  }
}
