.custom-info {
  // position: absolute;
  // top: 53px;
  // left: 0;
  color: #fff;
  width: 100%;
  background-color: #8a0719;
  display: none;
}

.custon-info-message {
  max-width: 1140px;
  padding: 3px 10px;
  margin: auto;
  font-size: 90%;
}

[data-info="true"] {
  .leaflet-control-zoom {
    margin-top: 50px !important;
  }

  // @media (max-width: 991px) {
  //   #navbarNavDropdown {
  //     margin-top: 30px;
  //   }
  // }
  .custom-info {
    display: block;
  }
}

.site-locator {
  .custom-info {
    position: absolute;
    z-index: 999;
  }
}
