.customers-header {
  background-color: var(--main-color-bg);
}

.custon-info-message a {
  color: #fff;
  text-decoration: none;
}

.custon-info-message a:hover {
  color: #fff;
  border-bottom: 1px solid #fff;
  width: 100%;
}

.links-style {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  transition: color 0.3s ease;
  border: none;
  outline: none;
  background: transparent;
  margin: 0;
}

.links-style:hover {
  color: #cecece;
  background-color: transparent;
}

.links-style:hover:after {
  background-color: transparent;
}

.links-style.active {
  color: #cecece;
}

.links-style.active:after {
  background-color: transparent;
}

.navbar-nav {
  flex-grow: initial;
  margin: auto;
}

.customers-header {
  height: 54px;
}

.customers-header .navbar-toggler {
  color: #fff;
}

.custom-navbar {
  display: flex;
  list-style-type: none;
  overflow: hidden;
  gap: 15px;
  margin: auto 0;
}

.custom-dropdown:hover svg {
  fill: #cecece;
}

.custom-dropdown svg {
  fill: #fff;
  width: 15px;
  height: 15px;
}

.custom-dropdown-content {
  display: none;
  position: fixed;
  z-index: 9999;
}

.custom-dropdown:hover .custom-dropdown-content {
  display: block;
}

.custom-column {
  padding: 30px 0 20px;
  width: 230px;
}

.custom-column a {
  padding: 10px 20px;
  text-decoration: none;
  display: block;
}

.custom-column a:hover {
  background-color: #cd0022;
}

@media (max-width: 991px) {
  .customers-header .navbar-collapse {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .customers-header .custom-navbar {
    flex-direction: column;
    padding-bottom: 20px;
    gap: 0;
  }

  .customers-header button {
    padding: 0;
  }

  .customers-header .custom-column {
    width: 100%;
    padding: 0;
  }

  .customers-header .custom-dropdown-content {
    display: block;
    position: relative;
  }

  .customers-header .navbar-collapse {
    position: absolute;
    top: 53px;
    max-height: 75vh;
    width: calc(100% + 0.5rem);
  }

  .custom-column a {
    padding: 5px 20px;
  }
}

.btn-live {
  position: relative;
  width: 80px;
  color: #2e2e2e !important;
  background-color: #fff;
  padding: 5px 15px;
  border-radius: 50px;
  transition: background-color 2s linear;

  &:hover {
    background-color: #efefef !important;

    .live-icon {
      animation: none;
    }
  }
}

.live-icon {
  position: absolute;
  right: 12px;
  width: 10px;
  height: 10px;
  transform: translateY(50%);
  border-radius: 100%;
  background-color: var(--main-color-bg);
  animation: live 2s ease-in-out infinite;
}

@keyframes live {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
